.SubDetails {
  background: #fff;
  border: 1px solid #eaeaea;
  box-shadow: rgba(138, 137, 137, 0.1) 0px 2px 1px 0px;
}

.SubDetails__heading {
  background: #f5f5f5;
  padding: 10px 19px;

  h3 {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-shadow: 0px 0px #414141;
  }
}

.SubDetails__content {
  padding: 23px 19px 10px;
}

.semi-bold.order-summary-productname.word-wrap-break,
.SubDetails__product {
  margin: 0 0 20px;

  color: #494949;
  font-size: 18px;
  font-weight: 600;
  word-wrap: break-word;
}

.SubDetails__plan {
  font-size: 15px;
  color: rgb(58, 58, 58);
  wordbreak: break-all;
}

.SubDetails__divider {
  margin: 1rem 0;
  height: 0;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.SubDetails__trial {
  > strong {
    font-weight: 600;
  }
}
