.Button {
  padding: 10px 40px;
  padding: 0.4em 2.4em;
  max-width: 100%;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: #0069a3;

  color: #fff;
  cursor: pointer;
  font-family: 'Prompt', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  text-align: center;
}

.Button--large {
  padding: 0.4em 1.6em;
  font-size: 24px;
}
