.AppLayout {
  position: relative;
  margin: 0 auto;
  max-width: 980px;
  max-width: 100%;
  padding: 0 15px;

  display: flex;
  flex-direction: column;
}

.AppLayout > header {
  padding: 20px 0;
  text-align: center;
  line-height: 0;
}

@media (min-width: 576px) {
  .AppLayout {
    width: 540px;
  }
}

@media (min-width: 768px) {
  .AppLayout {
    width: 720px;
  }
}

@media (min-width: 992px) {
  .AppLayout {
    width: 980px;
  }
}
