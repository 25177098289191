.SignupForm {
  border: none;
  background-color: #fff;

  border-radius: 5px;
  box-shadow: rgba(138, 137, 137, 0.2) 0px 0px 8px 2px;

  display: flex;
  flex-direction: column;
}
.SignupForm__header {
  padding: 16px 0;

  background-color: rgb(0, 105, 163);
  border-radius: 5px 5px 0 0;

  color: white;
  font-size: 20px;
  line-height: 1.5;
  text-align: center;
  word-wrap: break-word;
}

.SignupForm__body {
  display: flex;
  flex-direction: column;
}

.SignupForm__primary-pane,
.SignupForm__secondary-pane {
  padding: 44px 15px 0;
}

.SignupForm__fieldset {
  margin: 0 -15px;
  padding: 0 0 0 15px;
  display: flex;
  flex-direction: column;

  border: none;
}
.SignupForm__fieldset p {
  margin-right: 15px;
}

.SignupForm__subhead {
  flex: 0 0 100%;
  display: flex;
  padding: 0 15px 15px 0;
  align-items: center;

  -ms-flex-preferred-size: calc(100% - 15px);

  .subhead-spacer {
    flex: 1 1 auto;
    margin-left: 8px;
    border-bottom: 1px solid #e0e5e8;
    height: 1px;
  }
}

.SignupForm__tl-notice {
  flex: 0 0 100%;
  color: #444;
}

.SignupForm__field {
  position: relative;
  flex: 0 0 auto;
  padding: 0 15px 27px 0;

  input {
    width: 100%;
  }
}

.SignupForm__help-text {
  font-size: 13px;
  margin: 5px 0 0;
}

textarea ~ .SignupForm__help-text {
  margin-top: 0;
}

.SignupForm__submit {
  margin: 10px 0 30px;
}

/* Two column layout */
@media (min-width: 768px) {
  .SignupForm__body {
    display: flex;
    flex-direction: row;
  }

  .SignupForm__primary-pane {
    order: 1;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;

    padding: 44px 35px 0;
    box-shadow: 4px 0 5px -2px #e5e5e5;

    -ms-flex-preferred-size: calc(58.333333% - 35px - 35px);
  }

  .SignupForm__secondary-pane {
    order: 2;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;

    padding: 44px 35px 0 30px;

    -ms-flex-preferred-size: calc(58.333333% - 35px - 30px);
  }

  .SignupForm__fieldset {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .SignupForm__field {
    position: relative;
    flex: 0 0 50%;
    padding: 0 15px 27px 0;

    -ms-flex-preferred-size: calc(50% - 15px);

    input {
      width: 100%;
    }
  }
  .SignupForm__field--full {
    flex: 0 0 100%;
    -ms-flex-preferred-size: calc(100% - 15px);
  }
}
