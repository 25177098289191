.TextInput {
  margin: 0;
  border: 1px solid #c0c5c8;
  padding: 5px 0 1px 14px;
  border-radius: 4px;
  width: 100%;
  min-height: 41px;
  background-color: #fff;
  background-clip: padding-box;

  color: #969595;
  font-family: 'Prompt', sans-serif;
  font-size: 14px;
  line-height: 1.25;

  overflow: visible;
  touch-action: manipulation;
  transition: border-color ease-in-out 150ms, box-shadow ease-in-out 150ms;
}

.TextInput:focus {
  border: 1px solid #2760be;
  outline: 0;
}

input[type='text'].TextInput--has-status {
  padding-right: 32px;
}

textarea.TextInput {
  resize: vertical;
  padding: 10px 14px;
  min-height: 72.5px;
}

.TextInput:disabled,
.TextInput[readonly] {
  background-color: rgba(236, 238, 239, 0.7);
}

.TextInput:focus ~ .TextInput__label {
  top: 0px;
  left: 10px;
  padding: 0px 5px;
  background: #ffffff;
  font-size: 12px;
  color: #2760be;
  opacity: 1;
  font-family: 'Prompt', Sans-serif !important;
  font-weight: 600;
}

input.TextInput:not([value='']):not(:focus) ~ .TextInput__label,
textarea.TextInput:not(:empty):not(:focus) ~ .TextInput__label {
  top: 0px;
  left: 10px;
  padding: 0px 5px;
  background: #ffffff;
  font-size: 12px;
  color: #2760be;
  opacity: 1;
  font-family: 'Prompt', Sans-serif !important;
  font-weight: 600;
}

.TextInput__label {
  position: absolute;
  pointer-events: none;
  left: 12px;
  color: #67757c;
  font-size: 15px;
  top: 20px;
  transition: 0.2s ease all;
  transform: translateY(-50%);
}

.TextInput:disabled ~ .TextInput__label,
.TextInput[readonly] ~ .TextInput__label {
  background-image: linear-gradient(
    transparent 50%,
    rgba(236, 238, 239, 0.7) 50%
  ) !important;
}

.TextInput__status {
  position: absolute;
  pointer-events: none;
  right: 25px;
  top: 10px;
  color: #67757c;
}

.TextInput__status--complete {
  color: rgb(14, 182, 97);
}

.TextInput__status--error {
  color: rgb(191, 34, 49);
}

.TextInput__help {
  font-size: 13px;
  margin: 5px 0 0;
  color: #969595;

  textarea ~ & {
    margin-top: 0;
  }
}

.TextInput__help--error {
  color: rgb(191, 34, 49);
}
