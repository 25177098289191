.FormHeader {
  position: relative;
  margin-bottom: 25px;

  font-family: 'Prompt', Sans-serif;
}

.FormHeader__num {
  display: block;
  position: absolute;

  width: 29px;
  height: 29px;
  border: 2px solid rgb(0, 105, 163);
  padding: 0;
  border-radius: 50%;
  background-color: #fff;

  color: rgb(0, 105, 163);
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.FormHeader__label {
  display: block;

  margin: 0;
  padding-left: 40px;

  color: rgb(70, 70, 70);
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
  text-shadow: 0px 0px rgb(70, 70, 70);
}
