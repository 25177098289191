.NoticeBox {
  margin: 60px auto 0;
  padding: 20px 15px 0;
  width: 100%;
  max-width: 480px;
  border: none;
  background-color: #fff;

  border-radius: 5px;
  box-shadow: rgba(138, 137, 137, 0.2) 0px 0px 8px 2px;

  display: flex;
  flex-direction: column;

  text-align: center;
}
.NoticeBox__header {
  padding: 16px 0;

  background-color: rgb(0, 105, 163);
  border-radius: 5px 5px 0 0;

  color: white;
  font-size: 20px;
  line-height: 1.5;
  text-align: center;
  word-wrap: break-word;
}

.NoticeBox__icon {
  margin: 0 auto 16px;
  transition: color 300ms;
}
